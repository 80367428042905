// timestamp of a past new moon (Jan 6, 2000, 6:14pm)
const START = +new Date('2022-01-02T18:33:00Z');

// lunar cycle in ms
const CYCLE = 2551442777.7776637;

export const calculateLunarPhase = (ts: number): number => {
  const cycle = ((ts - START) % CYCLE) / CYCLE;
  return cycle < 0.5 ? cycle * 4 - 1 : 1 - (cycle - 0.5) * 4;
};
