// timestamp of a past full moon in ms from unix epoch

import { calculateLunarPhase } from '../audioUtils/calculateLunarPhase';

registerProcessor(
  'LunarPhaseProcessor',
  class LunarPhaseProcessor extends AudioWorkletProcessor {
    process(_: Float32Array[][], [[output]]: Float32Array[][]): boolean {
      output.fill(calculateLunarPhase(Date.now()));
      return true;
    }
  },
);
